class arrayHelper {

    // helper to find duplicate values in an array of objects
    static findDuplicates(array, key) {
        const seen = {};
        const duplicates = [];

        array.forEach(item => {
            const value = item[key].toLowerCase();
            if (seen[value]) {
                duplicates.push(item);
            } else {
                seen[value] = true;
            }
        });

        return duplicates;
    }
}
export default arrayHelper;