export default (array, key, descending = false) => {
    return array.sort((a, b) => {
        let valueA = a[key];
        let valueB = b[key];

        // Convert strings to lowercase for case-insensitive sorting
        if (typeof valueA === 'string') {
            valueA = valueA.toLowerCase();
            valueB = valueB.toLowerCase();
        }

        // Determine the comparison result based on whether descending is true
        return descending ? (valueB.localeCompare(valueA)) : (valueA.localeCompare(valueB));
    });
};
