import {split} from "lodash";

const convertBase64ToImageFile = (data) => {

    let cleanedDataArray;
    cleanedDataArray = split(data, ",")
    const cleanedData = cleanedDataArray[1]
    const byteCharacters = atob(cleanedData)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, {type: 'image/png'})
}
export default convertBase64ToImageFile