/**
 * Prop builder
 */
export let props = {

  /**
   * Make a new prop from the arguments provided.
   */
  make: (name, type = [String, Boolean], defaultValue = undefined, required = false) => ({
    [name]: {
      type,
      default: defaultValue,
      required,
    }
  }),

  /**
   * Group one or more new props as generated in make().
   */
  group: mixed => ({ props: { ...mixed } })
}

/**
 * Computed prop builder
 */
export let computed = {
  make: mixed => ({ computed: { ...mixed } })
}

/**
 * Method builder
 */
export let methods = {
  make: mixed => ({ methods: { ...mixed } })
}
