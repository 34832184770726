import MapPolyfill from './map'

let map = (typeof Map === "function") ? new Map() : MapPolyfill()
let createEvent = name => new Event(name, { bubbles: true })

// IE does not support `new Event()`
try {
  new Event('test')
} catch (e) {
  createEvent = name => {
    let event = document.createEvent('Event')
    event.initEvent(name, true, false)
    return event;
  };
}

let assign = textarea => {
  if (!textarea || !textarea.nodeName || textarea.nodeName !== 'TEXTAREA' || map.has(textarea)) return

  let heightOffset = null, clientWidth = null, cachedHeight = null

  let init = () => {
    let style = window.getComputedStyle(textarea, null)

    if (style.resize === 'vertical') {
      textarea.style.resize = 'none'
    } else if (style.resize === 'both') {
      textarea.style.resize = 'horizontal'
    }

    if (style.boxSizing === 'content-box') {
      heightOffset = -(parseFloat(style.paddingTop) + parseFloat(style.paddingBottom))
    } else {
      heightOffset = parseFloat(style.borderTopWidth) + parseFloat(style.borderBottomWidth)
    }
    // Fix when a textarea is not on document body and heightOffset is Not a Number
    if (isNaN(heightOffset)) {
      heightOffset = 0
    }

    update()
  }

  let changeOverflow = value => {
    {
      // Chrome/Safari-specific fix:
      // When the textarea y-overflow is hidden, Chrome/Safari do not reflow the text to account for the space
      // made available by removing the scrollbar. The following forces the necessary text reflow.
      let width = textarea.style.width;
      textarea.style.width = '0px'

      // Force reflow:
      textarea.offsetWidth
      textarea.style.width = width
    }

    textarea.style.overflowY = value
  }

  let getParentOverflows = element => {
    let overflows = []

    while (element && element.parentNode && element.parentNode instanceof Element) {
      if (element.parentNode.scrollTop) {
        overflows.push({
          node: element.parentNode,
          scrollTop: element.parentNode.scrollTop,
        })
      }
      element = element.parentNode
    }

    return overflows
  }

  let resize = () => {
    if (textarea.scrollHeight === 0) {
      // If the scrollHeight is 0, then the element probably
      // has display:none or is detached from the DOM.
      return
    }

    let overflows = getParentOverflows(textarea),
      documentTop = document.documentElement && document.documentElement.scrollTop; // Needed for Mobile IE

    textarea.style.height = ''
    textarea.style.height = (textarea.scrollHeight + heightOffset) + 'px'

    // used to check if an update is actually necessary on window.resize
    clientWidth = textarea.clientWidth

    // prevents scroll-position jumping
    overflows.forEach(element => {
      element.node.scrollTop = element.scrollTop
    });

    if (documentTop) {
      document.documentElement.scrollTop = documentTop
    }
  }

  let update = () => {
    resize()

    let styleHeight = Math.round(parseFloat(textarea.style.height)),
      computed = window.getComputedStyle(textarea, null)

    // Using offsetHeight as a replacement for computed.height in IE, because IE does not account use of border-box
    let actualHeight = computed.boxSizing === 'content-box'
      ? Math.round(parseFloat(computed.height))
      : textarea.offsetHeight

    // The actual height not matching the style height (set via the resize method) indicates that
    // the max-height has been exceeded, in which case the overflow should be allowed.
    if (actualHeight < styleHeight) {
      if (computed.overflowY === 'hidden') {
        changeOverflow('scroll')
        resize()
        actualHeight = computed.boxSizing === 'content-box'
          ? Math.round(parseFloat(window.getComputedStyle(textarea, null).height))
          : textarea.offsetHeight
      }
    } else {
      // Normally keep overflow set to hidden, to avoid flash of scrollbar as the textarea expands.
      if (computed.overflowY !== 'hidden') {
        changeOverflow('hidden');
        resize();
        actualHeight = computed.boxSizing === 'content-box'
          ? Math.round(parseFloat(window.getComputedStyle(textarea, null).height))
          : textarea.offsetHeight;
      }
    }

    if (cachedHeight !== actualHeight) {
      cachedHeight = actualHeight
      let event = createEvent('autosize:resized')
      try {
        textarea.dispatchEvent(event)
      } catch (err) {
        // Firefox will throw an error on dispatchEvent for a detached element
        // https://bugzilla.mozilla.org/show_bug.cgi?id=889376
      }
    }
  }

  let pageResize = () => {
    if (textarea.clientWidth !== clientWidth) {
      update()
    }
  };

  let destroy = (style => {
    window.removeEventListener('resize', pageResize, false)
    textarea.removeEventListener('input', update, false)
    textarea.removeEventListener('keyup', update, false)
    textarea.removeEventListener('autosize:destroy', destroy, false)
    textarea.removeEventListener('autosize:update', update, false)

    Object.keys(style).forEach(key => {
      textarea.style[key] = style[key]
    })

    map.delete(textarea)
  }).bind(textarea, {
    height: textarea.style.height,
    resize: textarea.style.resize,
    overflowY: textarea.style.overflowY,
    overflowX: textarea.style.overflowX,
    wordWrap: textarea.style.wordWrap,
  });

  textarea.addEventListener('autosize:destroy', destroy, false)

  // IE9 does not fire onpropertychange or oninput for deletions,
  // so binding to onkeyup to catch most of those events.
  // There is no way that I know of to detect something like 'cut' in IE9.
  if ('onpropertychange' in textarea && 'oninput' in textarea) {
    textarea.addEventListener('keyup', update, false)
  }

  window.addEventListener('resize', pageResize, false)
  textarea.addEventListener('input', update, false)
  textarea.addEventListener('autosize:update', update, false)
  textarea.style.overflowX = 'hidden'
  textarea.style.wordWrap = 'break-word'

  map.set(textarea, {
    destroy,
    update,
  })

  init()
}

let destroy = textarea => {
  let methods = map.get(textarea)
  if (methods) methods.destroy()
}

let update = textarea => {
  let methods = map.get(textarea)
  if (methods) methods.update()
}

let autosize = null

// Do nothing in Node.js environment and IE8 (or lower)
if (typeof window === 'undefined' || typeof window.getComputedStyle !== 'function') {
  autosize = element => element
  autosize.destroy = el => el
  autosize.update = el => el
} else {
  autosize = (element, options) => {

    if (element) {
      Array.prototype.forEach.call(
        element.length
          ? element
          : [element],
        x => assign(x, options)
      )
    }

    return element
  }

  autosize.destroy = element => {
    if (element) {
      Array.prototype.forEach.call(
        element.length
          ? element
          : [element],
        destroy
      )
    }

    return element
  }

  autosize.update = element => {
    if (element) {
      Array.prototype.forEach.call(
        element.length
          ? element
          : [element],
        update
      )
    }

    return element
  }
}

export default autosize
