const ChangeType = {
    ADDED: 'added',
    CHANGED: 'changed',
    DELETED: 'deleted',
    UNCHANGED: 'unchanged'
};

export function mergeChecksWithChangeTypes(previousChecks, currentChecks) {
    const result = [];
    previousChecks.sort((a, b) => a > b);
    currentChecks.sort((a, b) => a > b);
    // Convert lists to maps for easy access by `id`
    const previousMap = new Map(previousChecks.map(item => [item.id, item]));
    const currentMap = new Map(currentChecks.map(item => [item.id, item]));

    // Check for items in the current list

    currentChecks.forEach(item => {
        const prevItem = previousMap.get(item.id);
        let changeType;
        let change_meta = ''
        if (!prevItem) {
            changeType = ChangeType.ADDED;
        } else if (
            item.sort !== prevItem.sort ||
            item.name !== prevItem.name
        ) {
            change_meta = prevItem.sort + 1;
            changeType = ChangeType.CHANGED;
        } else {
            changeType = ChangeType.UNCHANGED;
        }

        result.push({ ...item, changeType, change_meta });
    });

    // Check for items in previous but not in current (deleted items)
    previousChecks.forEach(item => {
        if (!currentMap.has(item.id)) {
            result.push({ ...item, changeType: ChangeType.DELETED });
        }
    });

    // Sort the result by sort_number
    result.sort((a, b) => a.sort - b.sort);

    return result;
}