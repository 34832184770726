let checkWidth = (el, binding) => {
  const mirror = document.querySelector(
    `.vue-input-autowidth-mirror-${el.dataset.uuid}`
  );
  const defaults = { maxWidth: "100%", minWidth: "10px", comfortZone: 4 }
  const options = Object.assign({}, defaults, binding.value)

  el.style.maxWidth = options.maxWidth
  el.style.minWidth = options.minWidth

  let val = el.value
  if (!val) val = el.placeholder || ""
  while (mirror.childNodes.length) {
    mirror.removeChild(mirror.childNodes[0])
  }
  mirror.appendChild(document.createTextNode(val))
  let newWidth = (mirror.getBoundingClientRect().width + options.comfortZone).toFixed(0)

  if (newWidth != el.getBoundingClientRect().width) {
    el.style.width = `${newWidth}px`
  }
}

export default {
  bind(el) {
    if (el.tagName.toLocaleUpperCase() !== "INPUT") {
      throw new Error("v-input-autowidth can only be used on input elements.")
    }
    el.dataset.uuid = Math.random()
      .toString(36)
      .slice(-5)
  },
  inserted(el, binding) {
    el.mirror = document.createElement("span")
    el.mirror.classList.add(`vue-input-autowidth-mirror-${el.dataset.uuid}`)
    let styles = window.getComputedStyle(el)
    Object.assign(el.mirror.style, {
      position: "absolute",
      top: "-9999px",
      left: "-9999px",
      width: "auto",
      whiteSpace: "pre",
      opacity: 0,
      border: styles.getPropertyValue("border"),
      fontSize: styles.getPropertyValue("font-size"),
      fontFamily: styles.getPropertyValue("font-family"),
      fontWeight: styles.getPropertyValue("font-weight"),
      fontStyle: styles.getPropertyValue("font-style"),
      fontFeatureSettings: styles.getPropertyValue("font-feature-settings"),
      fontKerning: styles.getPropertyValue("font-kerning"),
      fontStretch: styles.getPropertyValue("font-stretch"),
      fontVariant: styles.getPropertyValue("font-variant"),
      fontVariantCaps: styles.getPropertyValue("font-variant-caps"),
      fontVariantLigatures: styles.getPropertyValue("font-variant-ligatures"),
      fontVariantNumeric: styles.getPropertyValue("font-variant-numeric"),
      letterSpacing: styles.getPropertyValue("letter-spacing"),
      padding: styles.getPropertyValue("padding"),
      textTransform: styles.getPropertyValue("text-transform"),
      ariaHidden: true
    })
    document.body.appendChild(el.mirror)
    checkWidth(el, binding)
  },
  componentUpdated(el, binding) {
    checkWidth(el, binding)
  },
  unbind(el) {
    document.body.removeChild(el.mirror)
  }
};
