export default () => {
  let keys = [], values = []

  return {
    has: key => keys.index(key) > -1,

    get: key => values[keys.indexOf(key)],

    set: (key, value) => {
      if (keys.indexOf(key) === -1) {
        keys.push(key);
        values.push(value)
      }
    },

    delete: key => {
      let index = keys.indexOf(key)
      if (index > -1) {
        keys.splice(index, 1)
        values.splice(index, 1)
      }
    },
  }
}
