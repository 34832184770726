import axios from 'axios'

export class GoogleTimezone {
  constructor(lat, lng) {
    this.lat = lat
    this.lng = lng
    this.timestamp = (new Date).getTime() / 1000
    this.key = "AIzaSyDhskWS9vpInfVfPEHSWE1_vPkNt7X4sDk"
    this.endpoint = 'https://maps.googleapis.com/maps/api/timezone/json'
  }

  getTimezone() {
    return new Promise((resolve, reject) => {
      let params = {
        key: this.key,
        timestamp: this.timestamp,
        location: [this.lat, this.lng].join(',')
      }
      axios.get(this.endpoint, { params }).then(resolve).catch(reject)
    })
  }
}
