import filter from 'lodash/filter'
import find from 'lodash/find'

export class GoogleAddress {
  constructor(addressComponents, countries) {
    this.addressComponents = addressComponents
    this.street_number = this.getValue('street_number', 'long_name')
    this.street_name = this.getValue('route', 'long_name')
    this.street = `${this.street_number} ${this.street_name}`.trim()
    this.suburb = this.getValue('sublocality', 'long_name')
    this.city = this.getValue('locality', 'long_name')
    this.region = this.getValue('administrative_area_level_2', 'short_name')
    this.province = this.getValue('administrative_area_level_1', 'long_name')
    this.postalCode = this.getValue('postal_code', 'long_name')
    this.countryLongName = this.getValue('country', 'long_name')
    this.countryShortName = this.getValue('country', 'short_name')
    this.countryId = find(countries, country => country.alpha_2_code === this.countryShortName).id
  }

  getValue(_type, column) {
    const address_components = this.addressComponents
    const type = filter(address_components, component => find(component.types, type => type === _type))
    return (type && type.length > 0) ? type[0][column] : ''
  }
}
