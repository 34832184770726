/**
 * Generate a random string with the specified length.
 * @param {Number} length The length of the output string
 */
export let random = (length = 6) => {
  let symbols = 'abcdef0123456789';
  return Array.from(
    { length },
    () => symbols.charAt(Math.floor(Math.random() * symbols.length))
  ).join('')
}

/**
 * Generate a slugged version of a string
 * @param {String} input The input string
 */
export let slug = input => {
  let specials = {
    chars: 'àáäâãèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;',
    replace: 'aaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------',
  },
    specialCharsPiped = new RegExp(specials.chars.split('').join('|'), 'g')

  return input.toString().toLowerCase()
    .replace(/\s+/g, '-')
    .replace(specialCharsPiped, c => specials.replace.charAt(specials.chars.indexOf(c)))
    .replace(/&/g, '')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
}

/**
 * Generate a camelCased version of a string
 * @param {String} input The input string
 */
export let camel = input => {
  let [first, ...acc] = input.replace(/[^\w\d]/g, ' ').split(/\s+/)
  return first.toLowerCase() + acc.map(x => x.charAt(0).toUpperCase()
    + x.slice(1).toLowerCase()).join('')
}

/**
 * Format a string using named keys.
 * @param {String} input The input string with {named} or :named placeholders
 * @param {Object} replacement The replacement object with named keys
 */
export let format = (input, replacement) => {
  return input.replace(
    /:([a-zA-z0-9$-]+)|{((?:(?!{|}).)+)}/g,
    (...matches) => replacement[matches[1] || matches[2]] || matches[0]
  )
}

export default {
  random,
  slug,
  camel,
  format
}
